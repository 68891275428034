<template>
  <oiliness-template
    :gender="gender"
    :has-some-greasiness="hasSomeGreasiness"
    :greasy-skin="greasySkin"
    :greasy-skin-ai-selected="greasySkinAiSelected"
    :open-facial-pores="openFacialPores"
    :open-facial-pores-ai-selected="openFacialPoresAiSelected"
    :greasy-skin-locations="greasySkinLocations"
    :greasy-skin-locations-ai-selected="greasySkinLocationsAiSelected"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import OilinessTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-oiliness/oiliness/OilinessTemplate';

import { makeStep, mapAiSelectedFields, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { no, yes } from '@/modules/questionnaire/api/helpers';

import { AMOUNT } from '@/modules/questionnaire/api/constants';

const { field, requiredField } = fieldBuilder;

const FIELD_NAMES_TO_RESET = ['openFacialPores', 'greasySkinLocations'];

const AI_SELECTED_FIELD_NAMES = ['greasySkin', ...FIELD_NAMES_TO_RESET];

const FIELDS = [field('gender'), ...AI_SELECTED_FIELD_NAMES.map(requiredField)];

const CONDITIONAL_FIELDS = ['openFacialPores', 'greasySkinLocations'];

export default {
  name: 'Oiliness',
  components: { OilinessTemplate },
  mixins: [makeStep(FIELDS), mapAiSelectedFields(AI_SELECTED_FIELD_NAMES), resetStoreValues],
  computed: {
    hasSomeGreasiness() {
      return yes(this.greasySkin);
    }
  },
  watch: {
    greasySkin(newValue, oldValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(FIELD_NAMES_TO_RESET);
        this.showNextStep();

        return;
      }

      if (no(oldValue) && (newValue === AMOUNT.CONSIDERABLY || newValue === AMOUNT.MODERATELY)) {
        this.resetStoreFieldsByName(FIELD_NAMES_TO_RESET);
      }

      this.scrollTo('#open-facial-pores');
    },
    openFacialPores() {
      this.scrollTo('#greasy-skin-locations');
    },
    greasySkinLocations() {
      this.showNextStep();
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (CONDITIONAL_FIELDS.includes(fieldName)) {
        return this.hasSomeGreasiness;
      }

      return true;
    }
  }
};
</script>
